<template>
  <md-card>
    <md-card-header>
      <div class="md-layout md-alignment-center-left listing-table-header">
        <div class="md-layout-item md-size-20 table-header-item">
          <h1 class="md-title">Icon Editor Tokens</h1>
        </div>

        <div class="md-layout-item md-size-10 table-header-item">
          <md-field>
            <label for="itemsPerPage">Display</label>
            <md-select :value="editorTokensItemsPerPage"
                       name="itemsPerPage"
                       id="itemsPerPage"
                       @md-selected="refreshList"
                       md-dense>
              <md-option value="25">25</md-option>
              <md-option value="50">50</md-option>
              <md-option value="100">100</md-option>
            </md-select>
          </md-field>
        </div>

        <div class="md-layout-item md-size-20 table-header-item">
          <md-field>
            <label for="sortBy">Sort by</label>
            <md-select :value="editorTokensActiveSort.code"
                       name="sortBy"
                       id="sortBy"
                       @md-selected="sortFn"
                       md-dense>
              <md-option v-for="sortField in editorTokensSortFields"
                         :key="sortField.code"
                         :value="sortField.code">
                {{ sortField.name }}
              </md-option>
            </md-select>
          </md-field>
        </div>

        <div class="md-layout-item md-size-30 table-header-item">
          <md-field :md-clearable="true" @md-clear="clearsearch">
            <label for="search">Search...</label>
            <md-input name="search" id="search"
                      :value="editorTokensSearch"
                      @keyup="searchFn">
            </md-input>
          </md-field>
        </div>

        <div class="md-layout-item md-size-20 table-header-item">
          <md-button class="md-accent md-raised create-button"
                     @click="create">
            Create
          </md-button>
        </div>
      </div>
    </md-card-header>

    <md-card-content>
      <div class="md-layout">
        <div class="md-layout-item md-size-100">
          <listing-page :items="editorTokensItems"
                        :singleRouteName="'adminEditorTokenPageSingle'"
                        :columns="columns"
                        @onRemove="onRemove"
                        @unfetchUnlistedSites="unfetchUnlistedSites"
                        :active="active">
          </listing-page>
        </div>
      </div>
    </md-card-content>

    <md-card-actions>
      <div class="md-layout">
        <div class="md-layout-item md-size-50">
          Showing {{ editorTokensFrom }} to {{ editorTokensTo }} of {{ editorTokensTotal }}
        </div>
        <div class="md-layout-item md-size-50">
          <rec-paginator :current-page="editorTokensCurrentPage"
                         :last-page="editorTokensLastPage"
                         :threshold="2"
                         :has-prev-next="true"
                         :has-first-last="true"
                         @onFirstPage="onFirstPage"
                         @onPreviousPage="onPreviousPage"
                         @onNextPage="onNextPage"
                         @onLastPage="onLastPage"
                         @onChangePage="onChangePage">

          </rec-paginator>
        </div>
      </div>
      <EditorTokenModal @onClose="onClose" :active="active"></EditorTokenModal>
    </md-card-actions>
  </md-card>
</template>

<script>
import ListingPage from '@/components/page-templates/ListingPage.vue';
import RecPaginator from '@/components/pagination/RecPaginator.vue';
import EditorTokenModal from '@/components/modals/EditorTokenModal.vue';

import {
  FETCH_EDITOR_TOKENS,
  CLEAR_SEARCH_TOKENS,
  UPDATE_SEARCH_TOKENS,
  UPDATE_ACTIVE_SORT_FIELD_TOKENS,
  DELETE_TOKEN,
} from '@/store/modules/admin/resources/editorTokens/editorTokensActions.type';
import {
  UNFETCHD_UNLISTED_SITES_BY_TOKEN,
} from '@/store/modules/admin/resources/unlistedSites/unlistSitesActions.type';

export default {
  name: 'EditorTokens',
  components: {
    ListingPage,
    RecPaginator,
    EditorTokenModal,
  },
  data: () => ({
    columns: [
      {
        name: 'name',
        label: 'User',
      },
      {
        name: 'token',
        label: 'Token',
      },
      {
        name: 'number_of_fetched_sites',
        label: '#Fetched Sites',
      },
      {
        name: 'number_of_uploaded_logos_successfully',
        label: '#Uploaded Logos Successfully',
      },
      {
        name: 'number_of_failed_uploaded_logos',
        label: '#Failed Uploaded Logos',
      },
    ],
    active: false,
  }),
  computed: {
    editorTokensItems() {
      return this.$store.getters.editorTokensItems;
    },
    editorTokensItemsPerPage() {
      return this.$store.getters.editorTokensItemsPerPage;
    },
    editorTokensCurrentPage() {
      return this.$store.getters.editorTokensCurrentPage;
    },
    editorTokensLastPage() {
      return this.$store.getters.editorTokensLastPage;
    },
    editorTokensFrom() {
      return this.$store.getters.editorTokensFrom;
    },
    editorTokensTo() {
      return this.$store.getters.editorTokensTo;
    },
    editorTokensTotal() {
      return this.$store.getters.editorTokensTotal;
    },
    editorTokensSortFields() {
      return this.$store.getters.editorTokensSortFields;
    },
    editorTokensActiveSort() {
      return this.$store.getters.editorTokensActiveSort;
    },
    editorTokensSearch() {
      return this.$store.getters.editorTokensSearch;
    },
  },
  mounted() {
    this.initEditorTokens();
  },
  methods: {
    onClose() {
      this.active = false;
    },
    initEditorTokens() {
      const params = {
        page: this.editorTokensCurrentPage,
        perPage: this.editorTokensItemsPerPage,
      };
      this.fetchEditorTokens(params);
    },
    fetchEditorTokens(params) {
      this.$store.dispatch(FETCH_EDITOR_TOKENS, params)
        .then((response) => {
          this.$log.info('FETCH_EDITOR_TOKENS', response);
        })
        .catch((error) => {
          this.$log.error('FETCH_EDITOR_TOKENS', error);
        });
    },
    onFirstPage() {
      const params = {
        page: 1,
        perPage: this.editorTokensItemsPerPage,
      };
      this.fetchEditorTokens(params);
    },
    onPreviousPage() {
      const params = {
        page: this.editorTokensCurrentPage - 1,
        perPage: this.editorTokensItemsPerPage,
      };
      this.fetchEditorTokens(params);
    },
    onChangePage(data) {
      const params = {
        page: data,
        perPage: this.editorTokensItemsPerPage,
      };
      this.fetchEditorTokens(params);
    },
    onNextPage() {
      const params = {
        page: this.editorTokensCurrentPage + 1,
        perPage: this.editorTokensItemsPerPage,
      };
      this.fetchEditorTokens(params);
    },
    onLastPage() {
      const params = {
        page: this.editorTokensLastPage,
        perPage: this.editorTokensItemsPerPage,
      };
      this.fetchEditorTokens(params);
    },
    refreshList(data) {
      const params = {
        page: 1,
        perPage: data,
      };
      this.fetchEditorTokens(params);
    },
    clearsearch() {
      this.$store.dispatch(CLEAR_SEARCH_TOKENS)
        .then(() => {
          const params = {
            page: 1,
            perPage: this.editorTokensItemsPerPage,
          };
          this.fetchEditorTokens(params);
        })
        .catch((error) => {
          this.$log.error('CLEAR_SEARCH_TOKENS', error);
        });
    },
    searchFn(data) {
      if (data.key === 'Enter') {
        const searchValue = data.target.value;
        if (!searchValue || searchValue === '') {
          this.$store.dispatch(CLEAR_SEARCH_TOKENS)
            .then(() => {
              const params = {
                page: 1,
                perPage: this.editorTokensItemsPerPage,
              };
              this.fetchEditorTokens(params);
            })
            .catch((error) => {
              this.$log.error('CLEAR_SEARCH_TOKENS', error);
            });
        } else if (searchValue !== '') {
          this.$store.dispatch(UPDATE_SEARCH_TOKENS, searchValue)
            .then(() => {
              const params = {
                page: 1,
                perPage: this.editorTokensItemsPerPage,
              };
              this.fetchEditorTokens(params);
            })
            .catch((error) => {
              this.$log.error('UPDATE_SEARCH_TOKENS', error);
            });
        }
      }
    },
    sortFn(data) {
      this.$store.dispatch(UPDATE_ACTIVE_SORT_FIELD_TOKENS, data)
        .then(() => {
          const params = {
            page: 1,
            perPage: this.editorTokensItemsPerPage,
          };
          this.fetchEditorTokens(params);
        })
        .catch((error) => {
          this.$log.error('UPDATE_ACTIVE_SORT_FIELD_TOKENS', error);
        });
    },
    onRemove(item) {
      this.$store.dispatch(DELETE_TOKEN, item.id)
        .then((response) => {
          this.$log.info('DELETE_TOKEN', response);
          const params = {
            page: this.editorTokensCurrentPage,
            perPage: this.editorTokensItemsPerPage,
          };
          this.fetchEditorTokens(params);
          this.$toasted.show(response.message, {
            type: 'success',
            duration: 5000,
            icon: 'check',
          });
        })
        .catch((error) => {
          this.$log.error('DELETE_TOKEN', error);
        });
    },
    unfetchUnlistedSites(item) {
      this.$store.dispatch(UNFETCHD_UNLISTED_SITES_BY_TOKEN, item.id)
        .then((response) => {
          this.initEditorTokens();
          this.$log.info('UNFETCHD_UNLISTED_SITES_BY_TOKEN', response);
          this.$toasted.show('Users who use this token must use a fresh version of the editor app.', {
            type: 'warning',
            duration: 10000,
            icon: 'check',
          });
        })
        .catch((error) => {
          this.$log.error('UNFETCHD_UNLISTED_SITES_BY_TOKEN', error);
          this.$toasted.error(error.message, {
            type: 'error',
            duration: 5000,
            icon: 'alert',
          });
        });
    },
    create() {
      this.active = false; // to open the modal even i closed it before by clicking outside
      this.active = true;
    },
  },
};
</script>

<style lang="scss">
.listing-table-header {
  .table-header-item {
    padding: 0.5rem;
    .md-field {
      margin: 0;
    }
    .create-button {
      float: right;
    }
  }
}

.md-card-actions, .md-card-content, .md-card-header {
  padding: 1rem;
}

</style>
